import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { SocialIcon } from "react-social-icons";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Contact`}</h1>
    <p>{`Feel free to `}<a href="mailto:doucette.kr@gmail.com" target="_blank" id="eMail">{`say hi`}</a>{`! Or you can reach me on the platforms below.`}</p>
    <div className="flex-row" id="social">
  <SocialIcon url="https://www.linkedin.com/in/kristen-doucette-303bb22a/" mdxType="SocialIcon" />
  <SocialIcon url="https://github.com/Cosmic-Noir" mdxType="SocialIcon" />
  <SocialIcon url="https://www.instagram.com/_cosmic_noir_/" mdxType="SocialIcon" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      