import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Tool from "./components/tool";
import "./style/main.css";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Projects`}</h1>
    <br />
    <ProjectCard title="Balance" link="https://balance-app.cosmicnoir.now.sh/" bg="linear-gradient(to right, #01608f   0%, #a494eb 100%)" mdxType="ProjectCard">
  Gain balance with this finacial budgeting tool for planning monthly expenses.
  <Tool skills="JS/React, D3, Jest, Express/Knex/PSQL" mdxType="Tool" />
    </ProjectCard>
    <ProjectCard title="Trash Tracker" link="https://trash-tag-tracker-app.cosmicnoir.now.sh/" bg="linear-gradient(to right, #000000 0%, #b8b8b8 100%)" mdxType="ProjectCard">
  Participate in the #trashtag challenge and help clean up our planet.
  <Tool skills="JS/React, D3, Jest, Express/Knex/PSQL" mdxType="Tool" />
    </ProjectCard>
    <ProjectCard title="VidVoid" link="https://vidvoid.cosmicnoir.now.sh/" bg="linear-gradient(to right, #8304F4
 0%, #EB92EB 100%)" mdxType="ProjectCard">
  Explore and share the media universe with this galactic tool.
  <Tool skills="JS/jQuery" mdxType="Tool" />
    </ProjectCard>
    <ProjectCard title="Zelda Quiz" link="https://cosmic-noir.github.io/ZeldaQuizApp/" bg="linear-gradient(to right, rgb(0, 146, 69) 0%, rgb(252, 238, 33) 100%)" mdxType="ProjectCard">
  Interactive Zelda Ocarina of Time Quiz Application. Unlock Triforce mode!
  <Tool skills="JS/jQuery" mdxType="Tool" />
    </ProjectCard>
    <h1>{`My Favorite Learning Platforms`}</h1>
    <br />
    <ProjectCard title="Unity" link="https://learn.unity.com/u/cosmic_noir" bg="linear-gradient(to right, #000000 0%, #3ec1de 100%)" mdxType="ProjectCard">
  Unity game engine learnig profile. Best way to learn game development is their learning pathways!
    </ProjectCard>
    <ProjectCard title="Codecademy" link="https://www.codecademy.com/profiles/cosmic_noir" bg="linear-gradient(to right, #6700cf 0%, #e6e8fa 100%)" mdxType="ProjectCard">
  My favorite code and framework learning platform for HTML/CSS, Ruby/Rails, JS/React.
    </ProjectCard>
    <ProjectCard title="HackerRank" link="https://www.hackerrank.com/doucette_kr" bg="linear-gradient(to right, #0c0e1c 0%, #00c455 100%)" mdxType="ProjectCard">
  Fun way to practice your problem-solving skills and see how others do it too.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      